@import '@angular/material/theming';

@import './styles/custom-palettes';
@import './styles/color';
@import './styles/fonts';


/*** Angular Material Design Theme ***/
@include mat-core();

$ng-pilot-primary: mat-palette($prozper-bluetime-palette, 700);
$ng-pilot-accent: mat-palette($prozper-grey-palette, A100);
$ng-pilot-warn: mat-palette($prozper-deepred-palette, 500);

$ng-pilot-theme: mat-light-theme((
  color: (
    primary: $ng-pilot-primary,
    accent: $ng-pilot-accent,
    warn: $ng-pilot-warn,
  )
));

@include angular-material-theme($ng-pilot-theme);

/*** Define default font-family and style ***/
html, body {
  font-family: 'Noto Sans', 'Kanit';
  font-weight: 400;
  margin: 0;
}
h1, h2, h3, h4 {
  font-family: 'Noto Sans', 'Kanit';
  font-weight: 400;
}
button {
  font-family: 'Noto Sans', 'Kanit';
  font-weight: 500;  
}
select, input {
  font-family: 'Noto Sans', 'Kanit' !important;
  font-weight: 300 !important;
  line-height: 1.5 !important;
}
textarea {
  font-family: 'Noto Sans', 'Kanit' !important;
  font-weight: 300 !important;
  line-height: 1.5 !important;
  resize: none !important;
}
th {
  font-family: 'Noto Sans', 'Kanit';
  font-weight: 400;
}
td {
  font-family: 'Noto Sans', 'Kanit';
  font-weight: 300;
}


@import './styles/dialog';
@import './styles/snack-bar';
@import './styles/scrollbar';


// Customized Material Styles ______________________________________________________________________

mat-form-field {
  font-family: 'Noto Sans', 'Kanit' !important;
  font-weight: 300 !important;
  line-height: 1.4 !important;

  mat-placeholder {
    font-family: 'Noto Sans', 'Kanit';
    font-weight: 300;
    line-height: 1.4;
  }
}
mat-radio-button {
  font-family: 'Noto Sans', 'Kanit' !important;
  font-weight: 300;
}
.mat-form-field-placeholder {
  display: block !important;
}
.mat-menu-item {
  font-family: 'Noto Sans', 'Kanit' !important;
}
.mat-calendar {
  font-family: 'Noto Sans', 'Kanit';
  font-weight: 300;

  .mat-button-wrapper {
    font-family: 'Noto Sans', 'Kanit';
    font-weight: 400;
  }
}
.mat-tooltip {
  font-family: 'Noto Sans', 'Kanit' !important;
  font-weight: 400 !important;
}


/*** Background style ***/
.pz-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  z-index: -10;
}

/*** Main display panel style ***/
.pz-panel {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-flow: column;
}

/*** Toolbar styles ***/
.pz-toolbar {
  width: calc(100% - 40px);
  height: 60px;
  display: flex;
  flex-flow: row;
  padding-left: 20px;
  padding-right: 20px;
}
.pz-header-container {
  flex: 1 0 60%;
  display: flex;
  flex-flow: row;
  align-items: center;
}
.pz-header-text {
  font-family: 'Noto Sans', 'Kanit';
  font-size: 28px;
  font-weight: 400;
  color: color($colors, sand-shark);
}
.pz-toolbar-button-container {
  flex: 0 0 auto;
  display: flex;
  flex-flow: row;
  align-items: center;

  button {
    margin-left: 16px;
  }
}