// Font Definition _________________________________________________________________________________

// Roboto ..........................................................................................
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url('../assets/fonts/Roboto-Light.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('../assets/fonts/Roboto-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url('../assets/fonts/Roboto-Medium.woff') format('woff');
}

// Noto Sans .......................................................................................
@font-face {
  font-family: 'Noto Sans';
  font-weight: 300;
  src: url('../assets/fonts/NotoSans-Light.ttf');
}
@font-face {
  font-family: 'Noto Sans';
  font-weight: 400;
  src: url('../assets/fonts/NotoSans-Regular.ttf');
}
@font-face {
  font-family: 'Noto Sans';
  font-weight: 500;
  src: url('../assets/fonts/NotoSans-Medium.ttf');
}
@font-face {
  font-family: 'Noto Sans';
  font-weight: 600;
  src: url('../assets/fonts/NotoSans-SemiBold.ttf');
}
@font-face {
  font-family: 'Noto Sans';
  font-weight: 700;
  src: url('../assets/fonts/NotoSans-Bold.ttf');
}
@font-face {
  font-family: 'Noto Sans';
  font-weight: 800;
  src: url('../assets/fonts/NotoSans-ExtraBold.ttf');
}

// Kanit ...........................................................................................
@font-face {
  font-family: 'Kanit';
  font-weight: 300;
  src: url('../assets/fonts/Kanit-ExtraLight.ttf');
}
@font-face {
  font-family: 'Kanit';
  font-weight: 400;
  src: url('../assets/fonts/Kanit-Light.ttf');
}
@font-face {
  font-family: 'Kanit';
  font-weight: 500;
  src: url('../assets/fonts/Kanit-Regular.ttf');
}
@font-face {
  font-family: 'Kanit';
  font-weight: 600;
  src: url('../assets/fonts/Kanit-Medium.ttf');
}
@font-face {
  font-family: 'Kanit';
  font-weight: 700;
  src: url('../assets/fonts/Kanit-SemiBold.ttf');
}
@font-face {
  font-family: 'Kanit';
  font-weight: 800;
  src: url('../assets/fonts/Kanit-Bold.ttf');
}

// Spartan .........................................................................................
@font-face {
  font-family: 'Spartan';
  font-weight: 500;
  src: url('../assets/fonts/Spartan-Medium.ttf');
}