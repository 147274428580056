.mat-dialog-title {
  font: 400 20px 'Noto Sans', 'Kanit';
  color: color($colors, sand-shark);
  border-bottom: solid 1px color($colors, blue-sky) !important;
  margin-bottom: 8px;
}
.mat-dialog-actions {
  padding-bottom: 24px !important;

  button {
    border-radius: 18px !important;
  }
}
.pz-dialog-container .mat-dialog-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;  
  background-image: linear-gradient(#FCDC88, #FFFFFF 66.67%);
  padding: 0px !important;
  border-radius: 8px;
}
.input-dialog-container .mat-dialog-container {
  mat-dialog-content {
    display: initial;
  }
}


// Custom Dialog Styles ____________________________________________________________________________

.pz-icon-dialog-header {
  position: relative;
  width: 100%;
  z-index: 110;

  .pz-header-icon {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: color($colors, sand-shark);

    svg, img {
      width: 48px;
      height: 48px;
      margin: 0px;
    }      
  }
  .pz-icon-header-toolbar {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    height: 44px;
    background-image: linear-gradient(to bottom right, #6eebbd, #0dc9cc);
    justify-content: flex-end;
    align-items: center;
    
    .pz-icon-header-toolbar-button {
      width: 44px;
      height: 44px;
    }  
  }
  .pz-icon-header-title {
    display: flex;
    width: 100%;
    height: 44px;
    align-items: center;

    .pz-icon-header-title-text {
      width: 100%;
      font: 500 22px 'Noto Sans', 'Kanit';
      color: color($colors, sand-shark);
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 80px;
      padding-right: 16px;
    }
  }    
} 