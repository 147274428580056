.pz-information-snackbar {
  font-family: 'Noto Sans', 'Kanit';
  color: #FFFFFF;
  background-color: #536DFE !important;
  padding: 24px 48px !important;
}
.pz-success-snackbar {
  font-family: 'Noto Sans', 'Kanit';
  color: #212121;
  background-color: #69F0AE !important;
  padding: 24px 48px !important;
}
.pz-warning-snackbar {
  font-family: 'Noto Sans', 'Kanit';
  color: #212121;
  background-color: #FFAB40 !important;
  padding: 24px 48px !important;
}
.pz-error-snackbar {
  font-family: 'Noto Sans', 'Kanit';
  color: #FFFFFF;
  background-color: #FF5252 !important;
  padding: 24px 48px !important;
}