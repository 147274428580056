// Overwrite Scrollbar Style _______________________________________________________________________

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(13, 201, 204, 0.3);
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: color($colors, blue-sky);
  border-radius: 3px;
}